import React, { useContext } from "react"
import Layout from "../../components/layout"
import NullIf from "../../services/NullIf"
import ThemeContext from "../../context/ThemeContext"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import RichText from "../../components/text-helpers/rich-text"
import localize from "../../components/localize"
import BlockContent from "../../components/text-helpers/portable-text"

const Content = ({ post, translations, locale }) => {
  let { bonuses } = useContext(ThemeContext)

  return (
    <div>
      {NullIf.isNotNull(post.heroImage) ? (
        <Img fluid={post.heroImage.asset.fluid} />
      ) : (
        ""
      )}

      <div className={`gambleGeneralWrapper-blog`}>
        {post._rawCombinedBody && <BlockContent blocks={post._rawCombinedBody} />}
      </div>
    </div>
  )
}

const CasinoNewsItem = ({ data, pageContext, location }) => {
  let post = data.sanityBlogPost
  let siteTitle = "Mr Gamble"

  const { locale, alternateLocaleSlugs } = pageContext

  let { translations } = useContext(ThemeContext)

  return (
    <Layout silo={data.sanitySilo} pageContext={pageContext} location={location}>
      <SEO location={location} alternateSlug={alternateLocaleSlugs}
        title={`${post && post.title} | ${siteTitle}`}
        description={post.metaDescription}
      />
      <Content translations={translations} locale={locale} post={post} />
    </Layout>
  )
}

export default localize(CasinoNewsItem)
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    sanitySilo (_id: {eq: "6c6a3bda-364c-482e-a153-4ecd7a12c9de"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
    sanityBlogPost(id: { eq: $slug }) {
      ...BlogPost
    }
  }
`
